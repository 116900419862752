import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  open(message: string, action: string = 'Close') {
    environment.production?   this.snackBar.open(message, action, {
      duration: 0, // Duration in milliseconds
     }):

    this.snackBar.open(message, action, {
     duration: 300, // Duration in milliseconds
    });
  }
}
