<mat-dialog-content>
    <form [formGroup]="leaveForm" (ngSubmit)="submit()" novalidate>
        <h2 mat-dialog-title  class="justify-center">واجهه الايجازات والاعفاءات </h2>
        <div class="flex flex-col ">

      <mat-form-field class="flxe-auto">
        <mat-label>ابحث عن موظف (الاسم \رقم كود )</mat-label>
        <input matInput   [formControl]="search"  required>
        <button mat-icon-button matSuffix (click)="searchEmployee()">
          <mat-icon svgIcon="mat:search"></mat-icon>

        </button>
        <mat-error *ngIf="leaveForm.get('empId')?.hasError('required')">بيانات الموظف مهمه </mat-error>
      </mat-form-field>
    </div>


      <div class="flxe-auto m-3 p-2" *ngIf="employee !==null">
        <mat-label>
       <span><strong> {{ employee ? 'الموظف ==> ' + employee.name : '' }}</strong></span>    
        </mat-label>
      </div>

      <mat-checkbox formControlName="deductFromBalance">هل تريد خصمها من رصيده السنوي</mat-checkbox>
      <div class="flex flex-col ">        
            <mat-form-field class="flxe-auto">
        <mat-label>نوع الاعفاء</mat-label> 
        <mat-select formControlName="type" required>          
          <mat-option [value]="0">مرضية</mat-option>
          <mat-option [value]="1">خاصة</mat-option>
          <mat-option [value]="2">مهمة_عمل</mat-option>
          <mat-option [value]="3">حالة_وفاة</mat-option>
          <mat-option [value]="4">اخري</mat-option>
        </mat-select>        
        <mat-error *ngIf="leaveForm.get('type')?.hasError('required')">نوع الاعفاء مطلوب</mat-error>
      </mat-form-field>
      </div>
      <div class="flex flex-col ">
            <mat-form-field class="flxe-auto">
        <mat-label>من تاريخ</mat-label>
        <input matInput formControlName="from" type="date" required>
        <mat-error *ngIf="leaveForm.get('from')?.hasError('required')">من تاريخ مطلوب</mat-error>
      </mat-form-field>
      <mat-form-field class="flxe-auto">
        <mat-label>عدد الايام</mat-label>
        <input matInput formControlName="noOfDays" type="number" required>
        <mat-error *ngIf="leaveForm.get('noOfDays')?.hasError('required')">عدد الايام مطلوب</mat-error>
      </mat-form-field>
      </div>
      <div class="flex flex-col ">
       <mat-form-field class="flxe-auto">
        <mat-label>السبب</mat-label>
        <textarea matInput formControlName="reason" ></textarea>
      </mat-form-field>    
      </div>
   
      <div class="mt-4 flex flex-col gap-2" >
        <mat-form-field class="vex-flex-form-field flex-auto">
          <mat-label>تحميل الملف</mat-label>
          <div style="display: flex; align-items: center; gap: 10px;">
            <ngx-mat-file-input formControlName="uploadfile" matInput required (change)="onFileCardSelected($event)"></ngx-mat-file-input>
            <img id="logoPreview" src="assets/img/medical/user.jpg" style="width: 50px; height: 50px;" alt="Preview">
          </div>
         </mat-form-field>
      </div>

 
      <div>
        <button mat-raised-button color="primary" [disabled]="!leaveForm.valid" type="submit" >حفظ</button>
        <button mat-raised-button color="warn" (click)="onNoClick()" type="button">الغاء</button>
      </div>
    </form>
  </mat-dialog-content>
  