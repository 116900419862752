import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { Department } from 'src/assets/models/employees.model';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {

  private selectedDepartmentSubject = new BehaviorSubject<any>(null);
  selectedDepartment$ = this.selectedDepartmentSubject.asObservable();

  setSelectedDepartment(department: any) {
    this.selectedDepartmentSubject.next(department);
  }
  // deleteDepartment(depId: number) {
  //   return this.http.delete(`${this.apiUrl+'/department'}/${depId}`).pipe(
  //     map(response => (response))
  //   );
  // }
  createDepartment(departmentData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/department`, departmentData);
  }
  downloadxls(depId: number): void {
    const url = `${this.apiUrl+'/department'}/XLS/${depId}`;

    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      const fileName = `Employees_Department_${depId}.xlsx`;
      saveAs(blob, fileName);
    }, error => {
      console.error('File download failed:', error);
    });
  }

  private apiUrl =  environment.apiUrl;
  private empssSource = new BehaviorSubject<Department[]>([]);

  constructor(private http: HttpClient) {
this.getDepartmentHierarchy();

  }

  getDepartment(): Observable<Department[]> {
    return this.http.get<Department[]>(this.apiUrl+'/department').pipe(
      tap(surveys => {
        console.log(surveys)
        // تحديث الـ BehaviorSubject بالاستبيانات الجديدة
        this.empssSource.next(surveys);
      })
    );
  }
  private departmentsSubject = new BehaviorSubject<DepartmentNode[]>([]);
  departments$ = this.departmentsSubject.asObservable();
 

  getDepartmentHierarchy(): Observable<DepartmentNode[]> {
    // Return cached data if available
    const cachedDepartments = this.departmentsSubject.getValue();
    if (cachedDepartments.length > 0) {
      return of(cachedDepartments); // Return cached data as observable
    } else {
      // Fetch data from API and update cache
      return this.http.get<DepartmentNode[]>(`${this.apiUrl}/Department/hierarchy`).pipe(
        tap(departments => this.departmentsSubject.next(departments))
      );
    }
  }

  deleteDepartment(depId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/department/${depId}`).pipe(
      tap(() => {
        // Remove department from the cached data
        const updatedDepartments = this.removeDepartmentFromCache(depId);
        this.departmentsSubject.next(updatedDepartments);
      })
    );
  }

  private removeDepartmentFromCache(depId: number): DepartmentNode[] {
    const departments = this.departmentsSubject.getValue();
    return this.filterDepartments(departments, depId);
  }

  private filterDepartments(departments: DepartmentNode[], depId: number): DepartmentNode[] {
    return departments.reduce((result: DepartmentNode[], department: DepartmentNode) => {
      if (department.departmentId !== depId) {
        const children = this.filterDepartments(department.children || [], depId);
        result.push({ ...department, children });
      }
      return result;
    }, []);
  }
 
  getDepartmentDetails(depId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/department/depId?depId=${depId}`);
  }

  downloadEmployeesFile(depId: number): Observable<Blob> {
    console.log(depId)
    return this.http.get(`${this.apiUrl}/department/ExportEmpsByDepId?depId=${depId}`, {
      // params: { depId: depId.toString() },
      responseType: 'blob'
    }); 
  }

  updateDepartment(updateDto: any): Observable<any> {
    const url = `${this.apiUrl}/department`;
    
    // Step 1: Send the update request to the server
    return this.http.put(url, updateDto).pipe(
      // Step 2: On successful response, clear the existing cache
      tap((rep) => {
        console.log('Response:', rep);
        
        // Clear the existing departments cache
        this.departmentsSubject.next([]);
        
        // Step 3: Fetch the updated department hierarchy from the server
        return this.http.get<DepartmentNode[]>(`${this.apiUrl}/Department/hierarchy`).pipe(
          tap(departments => {
            // Update the departments cache with the new data
            this.departmentsSubject.next(departments);
          }),
          catchError(this.handleError) // Handle errors in fetching new data
        );
      }),
      catchError(this.handleError) // Handle errors in updating department
    );
  }
  

  private updateDepartmentInCache(updatedDept: any): DepartmentNode[] {
    const departments = this.departmentsSubject.getValue();
    return this.updateDepartmentRecursively(departments, updatedDept);
  }

  private updateDepartmentRecursively(departments: DepartmentNode[], updatedDept: any): DepartmentNode[] {
    return departments.map(dept => {
      if (dept.departmentId === updatedDept.id) {
        // Update only the name and parentDepartmentId
        return { ...dept, name: updatedDept.name, parentDepartmentId: updatedDept.parentDepartmentId };
      }
      if (dept.children) {
        return { ...dept, children: this.updateDepartmentRecursively(dept.children, updatedDept) };
      }
      console.log(dept)
      return dept;
    });
  }

 



  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    // يمكنك هنا تخصيص كيفية التعامل مع الأخطاء، مثل عرض رسالة للمستخدم
    throw error;
  }

  downloadEmployeesByDepartment(depId: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/XLS/${depId}`, { responseType: 'blob' });
  }
 }

// department-node.model.ts
export interface DepartmentNode {
  departmentId: number;
  name: string;
  parentDepartmentId?: number;
  children: DepartmentNode[];
  isSelected?:boolean

}
 
export interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  departmentId: number;
  isSelected?:boolean
  id?:number
}