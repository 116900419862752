import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';


import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/user/user.service';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  /**
   *
   */
  constructor(private accountService: UserService ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  {


      return this.accountService.currentUser$.pipe(
        map(user => {
          console.log(user)
        if(user.roles.includes('admin') ||user.roles.includes('Admin') || user.roles.includes('moderator')){
         return true;
        }
       // this.toastrService.error('You cannot enter this area');
        return false;
      }))
  }

}
