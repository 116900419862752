<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    {{node.name}}
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name">

        <!-- {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}} -->
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="mat:person_add"></mat-icon>


    </button>
    {{node.name}}
  </mat-tree-node>
</mat-tree>
