import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TreeRoutingModule } from './tree-routing.module';
import {MatTreeModule} from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { TreeComponent } from './tree.component';

@NgModule({
  declarations: [TreeComponent],
  imports: [
    CommonModule,
    TreeRoutingModule,
    MatTreeModule,
    MatIconModule
  ],
  exports:[
    TreeComponent
  ]
})
export class TreeModule { }
