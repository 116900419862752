 
<div class="card flex-auto" style="border: 1px dotted #ccc; padding: 8px; height: 1300px; overflow: auto;">
  <h2 class="matCardTitle">
    <mat-icon class="mr-1" svgIcon="mat:assignment"></mat-icon>
    الهيكل الاداري  </h2>

  <div class="flex flex-col sm:flex-row">
    <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl" class="example-tree sm:sm:mr-3 flex-auto tree-container">
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild;" matTreeNodePadding
        [ngClass]="{
          'tree-node-level-0': node.level === 0, 
          'tree-node-level-1': node.level === 1 && node.isLeaf === false, 
          'tree-node-level-2': node.level > 1 && node.isLeaf === false, 
          'tree-node-leaf': node.isLeaf
        }">
        <button mat-icon-button (click)="onNodeClick(node)"  >
          <mat-icon *ngIf="treeControl.isExpanded(node)" svgIcon="mat:expand_more"></mat-icon>
          <mat-icon *ngIf="!treeControl.isExpanded(node)" svgIcon="mat:chevron_right"></mat-icon>
        </button>

        <button mat-icon-button (click)="toggleNodeSelection(node)"  >
          <mat-icon *ngIf="node.isSelected" [ngClass]="node.isSelected ? 'icon-remove' : 'icon-add'" svgIcon="mat:remove_circle"></mat-icon>
          <mat-icon *ngIf="!node.isSelected" [ngClass]="node.isSelected ? 'icon-remove' : 'icon-add'" svgIcon="mat:add_circle"></mat-icon>
        </button>

        {{ node.name }}
        <ng-container matTreeNodeOutlet></ng-container>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
        [ngClass]="{
          'tree-node-level-0': node.level === 0, 
          'tree-node-level-1': node.level === 1 && node.isLeaf === false, 
          'tree-node-level-2': node.level > 1 && node.isLeaf === false, 
          'tree-node-leaf': node.isLeaf
        }">
        <!-- <button mat-icon-button *ngIf="!node.isLeaf" (click)="onNodeClick(node)">
          <mat-icon *ngIf="treeControl.isExpanded(node)" svgIcon="mat:expand_more"></mat-icon>
          <mat-icon *ngIf="!treeControl.isExpanded(node)" svgIcon="mat:chevron_right"></mat-icon>
        </button> -->
        <button mat-icon-button (click)="toggleNodeSelection(node)"  >
          <mat-icon *ngIf="node.isSelected" [ngClass]="node.isSelected ? 'icon-remove' : 'icon-add'" svgIcon="mat:remove_circle"></mat-icon>
          <mat-icon *ngIf="!node.isSelected" [ngClass]="node.isSelected ? 'icon-remove' : 'icon-add'" svgIcon="mat:add_circle"></mat-icon>
        </button>
        {{ node.name }}
      </mat-tree-node>
    </mat-tree>
  </div>
</div>

