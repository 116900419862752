import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsService } from '../departments.service';
import { SnackbarService } from 'src/app/snackbarService';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'LIBYANA-department-create-update',
  templateUrl: './department-create-update.component.html',
  styleUrls: ['./department-create-update.component.scss']
})
export class DepartmentCreateUpdateComponent implements OnInit {
  departmentForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private departmentService: DepartmentsService,
    private snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DepartmentCreateUpdateComponent>

  ) {
    this.createForm();

    console.log(data)
  }

  ngOnInit(): void {
    if (this.data) {
      this.departmentForm.patchValue(this.data);
    }
  }

  createForm() {
    this.departmentForm = this.fb.group({
      name: ['', Validators.required],
      descrp: [''],
      parentDepartmentId: [this.data.id]
    });
  }

  saveDepartment() {
    if (this.departmentForm.valid) {
      const departmentData = this.departmentForm.value;

      this.departmentService.createDepartment(departmentData).subscribe(
        response => {
          this.snackBar.open('Department saved successfully!');
          this.dialogRef.close(response)
        },
        error => {
          console.error('Error saving department:', error);
          this.snackBar.open('Failed to save department. Try again.', 'Close');
        }
      );
    }
  }
}
