


import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, filter } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';

import { ConfirmDialogComponent } from '../ShareComponent/confirm-dialog/confirm-dialog.component';
import { stagger, trigger, style, transition, animate, state } from '@angular/animations';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { OverTimeOrder } from 'src/assets/models/employees.model';
 import { AddLeavesComponent } from '../leaves/add-leaves/add-leaves.component';
import { OverTimeOrderService } from './over-time-order.service';
import { UserService } from '../user/user.service';

@Component({
  selector: 'LIBYANA-over-time-order',
  templateUrl: './over-time-order.component.html',
  styleUrls: ['./over-time-order.component.scss'],

  animations: [
    fadeInUp400ms,
    stagger40ms,stagger,
      trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],


  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
@UntilDestroy() // إضافة هذا الديكور هنا

export       class OverTimeOrderComponent  implements OnInit, AfterViewInit  {

  layoutCtrl = new UntypedFormControl('boxed');
  isTableExpanded = false;

  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;

    this.dataSource.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
    })
  }



  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<  OverTimeOrder[]> = new ReplaySubject<OverTimeOrder[]>(1);
  data$: Observable<  OverTimeOrder[]> = this.subject$.asObservable();
  customers:   OverTimeOrder[];

  // columns: TableColumn<   OverTimeOrder>[] = [

  //   { label: 'الايصال', property: ' User OverTimeOrderCode', type: 'text', visible: true, cssClasses: ['font-medium'] },
  //   { label: 'ولي الامر', property: 'parentName', type: 'text', visible: true, cssClasses: ['font-medium'] },
  //   { label: 'قيمة المتبقة', property: 'totalDebit', type: 'text', visible: true, cssClasses: ['font-medium'] },
  //   { label: 'القيمة المدفوع', property: 'totalAmount', type: 'text', visible: true, cssClasses: ['font-medium'] },
  //    { label: 'القيمة الكلية', property: 'totalOrder', type: 'text', visible: true, cssClasses: ['font-medium'] },
  //   { label: 'تاريخ الفاتورة,' ,property: ' User OverTimeOrderCreate', type: 'text', visible: true, cssClasses: ['font-medium'] },
  //   { label: 'حالة الدفع', property: ' User OverTimeOrderStatues', type: 'button', visible: true, cssClasses: ['font-medium'] },

  //    { label: 'تعديلات', property: 'actions', type: 'button', visible: true } ,

  // ];


  columns: TableColumn<OverTimeOrder>[] = [
    { label: 'المعرف', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'], matColumnDef: 'id' },
    { label: 'الوصف', property: 'descrp', type: 'text', visible: true, cssClasses: ['font-medium'], matColumnDef: 'descrp' },
    { label: 'من تاريخ', property: 'fromDate', type: 'text', visible: true, cssClasses: ['font-medium'], matColumnDef: 'fromDate' },
    { label: 'إلى تاريخ', property: 'toDate', type: 'text', visible: true, cssClasses: ['font-medium'], matColumnDef: 'toDate' },
    { label: 'رقم الموظف', property: 'empId', type: 'text', visible: true, cssClasses: ['font-medium'], matColumnDef: 'empId' },
    { label: 'اسم الموظف', property: 'empName', type: 'text', visible: true, cssClasses: ['font-medium'], matColumnDef: 'empName' },


    { label: 'تعديلات', property: 'actions', type: 'button', visible: true }    ];



  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<  OverTimeOrder> | null;
  selection = new SelectionModel<  OverTimeOrder>(true, []);
  searchCtrl = new UntypedFormControl();


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  checkUserRoles(roles: string[]): boolean {
    const allowedRoles = ['HR_Editor', 'HR_Admin', 'System_Administrator'];
    return roles.some(role => allowedRoles.includes(role));
  }
   constructor(public user:UserService,private dialog: MatDialog,private member: OverTimeOrderService) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  ngOnInit() {
    this.member.getOverTimeOrder().subscribe((rep) => {
      this.subject$.next(rep);
    });

    this.data$.pipe(
      filter< OverTimeOrder[]>(Boolean)
    ).subscribe(customers => {
      this.customers = customers;
      if (!this.dataSource) {
        this.dataSource = new MatTableDataSource< OverTimeOrder>(customers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource.data = customers;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }


  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  createCustomer() {
    const dialogConfig = new MatDialogConfig();
dialogConfig.width = '900px' ,dialogConfig.height='auto'; // Set the desired width here

this.dialog.open(
    AddLeavesComponent

  , dialogConfig) .afterClosed().subscribe((customer:   OverTimeOrder) => {
      /**
       * Customer is the updated customer (if the user pressed Save - otherwise it's null)
       */
      if (customer) {

    //     this.customers.unshift(customer);
    //  this.subject$.next(this.customers);
    // this.member.get   OverTimeOrder().subscribe((rep) => {
    //   this.subject$.next(rep);
    // });
    //   }
   } });
  }

  updateCustomer(customer:   OverTimeOrder) {
    this.dialog.open( AddLeavesComponent, {
      data: customer ,width:'900px' ,height:'auto'
    }).afterClosed().subscribe(updatedCustomer => {
      /**
       * Customer is the updated customer (if the user pressed Save - otherwise it's null)
       */
      if (updatedCustomer) {
        /**
         * Here we are updating our local array.
         * You would probably make an HTTP request here.
         */
        // const index = this.customers.findIndex((existingCustomer) => existingCustomer.id === updatedCustomer.id);
        // this.customers[index] = (updatedCustomer);
        // this.subject$.next(this.customers);
      }
    });
  }

  deleteCustomer(customer:    OverTimeOrder) {

      console.log(customer)
      this.dialog
      .open(ConfirmDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {

            // this.member.delete   OverTimeOrder(customer).subscribe((rep)=> {
            //   console.log(rep);

            //   this.customers.splice(this.customers.findIndex((existingCustomer) => existingCustomer.id === customer.id), 1);
            //   this.selection.deselect(customer);
            //   this.subject$.next(this.customers);

            // });
          }



  });
}

handleFileUpload(event: any, subject: any): void {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onloadend = () => {
    const base64String = reader.result as string;
    subject.orderFileUpload = base64String;
  };
  reader.readAsDataURL(file);
}

handleAddClick(subject: any): void {
console.log(subject)
  let item = {
    id: subject.id,
       OverTimeOrderId: subject.   OverTimeOrderId,
     UserOverTimeOrderDateTiket: subject. UserOverTimeOrderDateTiket,
    orderFileUpload: subject.orderFileUpload
  };
  // this.member.update User OverTimeOrder(item).subscribe((rep) => {
  //   this.member.get   OverTimeOrder().subscribe((rep) => {
  //     this.subject$.next(rep);
 //  });
 // });
}
downloadFile(file: string) {
  const link = document.createElement('a');
  link.href = file;
  link.download = 'file';
  link.target = '_blank';
  link.click();
}
isImageType(file: string): boolean {
  return file.startsWith('data:image/');
}

isPdfType(file: string): boolean {
  return file.startsWith('data:application/pdf');
}

isWordType(file: string): boolean {
  return file.startsWith('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    || file.startsWith('data:application/msword');
}

isExcelType(file: string): boolean {
  return file.startsWith('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    || file.startsWith('data:application/vnd.ms-excel');
}
getFormattedAmount(amount: any): string {
  const parsedAmount = parseFloat(amount);
  return isNaN(parsedAmount) ? '' : parsedAmount.toFixed(2);
}

  deleteCustomers(customers:    OverTimeOrder[]) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    customers.forEach(c => this.deleteCustomer(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row:    OverTimeOrder) {
    const index = this.customers.findIndex(c => c === row);
    this.customers[index] = change.value;
    this.subject$.next(this.customers);
  }
}
