<form (ngSubmit)="saveDepartment()" [formGroup]="departmentForm">
    <div class="flex items-center mt-5" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">الادارة التابعة -{{data.name}}</h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>
  
    <mat-divider class="-mx-6 text-border"></mat-divider>
  
    <mat-dialog-content class="flex flex-col">
      <div class="flex flex-col sm:flex-row ">
        <mat-form-field class="flex-auto">
          <mat-label>Department Name</mat-label>
          <input matInput formControlName="name" placeholder="Department Name">
          <mat-error *ngIf="departmentForm.get('name')?.hasError('required')">
            Name is required
          </mat-error>
        </mat-form-field>
  </div>
  <div class="flex flex-col sm:flex-row ">

        <mat-form-field class="flex-auto">
          <mat-label>Description</mat-label>
          <input matInput formControlName="descrp" placeholder="Description">
        </mat-form-field>
      </div>
  
 
    </mat-dialog-content>
  
    <mat-dialog-actions align="end">
      <button mat-button type="submit" [disabled]="!departmentForm.valid">Save</button>
    </mat-dialog-actions>
  </form>
  