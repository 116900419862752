import { IMember } from 'src/assets/models/user';
import { Contact } from '../app/pages/apps/contacts/interfaces/contact.interface';

export const myimagePath = [
  {
    path: 'https://vex.visurel.com/',
    avatars : "/assets/img/avatars/" ,
  },
];


export const contactsDataMM: IMember[] = [

];




export const contactsData: Contact[] = [
  {
    /**
     *
     */

    id: 1,
    imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '1.jpg',
    name: 'Guerrero Morales',
    phone: '+21 (988) 504-2559',
    company: 'Supportal',
    email: 'guerrero.morales@supportal.com',
    selected: false,
    starred: false,
    isFriend:true
  },
  {
    id: 2,
    imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '2.jpg',
    name: 'Robin Blackburn',
    phone: '+5 (911) 423-2538',
    company: 'Polarax',
    email: 'robin.blackburn@polarax.biz',
    selected: false,
    starred: false   ,
     isFriend:true
  },
  {
    id: 3,
    imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '3.jpg',
    name: 'Simone Gallegos',
    phone: '+2 (948) 573-3528',
    company: 'Applidec',
    email: 'simone.gallegos@applidec.org',
    selected: false,
    starred: true
  },
  {
    id: 4,
    imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '4.jpg',
    name: 'Bonner Mcmillan',
    phone: '+13 (932) 426-2309',
    company: 'Magneato',
    email: 'bonner.mcmillan@magneato.io',
    selected: false,
    starred: false
  },
  // {
  //   id: 5,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '5.jpg',
  //   name: 'Velasquez Spence',
  //   phone: '+31 (850) 492-2858',
  //   company: 'Joviold',
  //   email: 'velasquez.spence@joviold.co.uk',
  //   selected: false,
  //   starred: false,
  //   isFriend:true
  // },
  // {
  //   id: 6,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '6.jpg',
  //   name: 'Orr Fernandez',
  //   phone: '+6 (843) 490-2487',
  //   company: 'Accuprint',
  //   email: 'orr.fernandez@accuprint.tv',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 7,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '7.jpg',
  //   name: 'Marcella Russo',
  //   phone: '+48 (836) 506-2890',
  //   company: 'Paprikut',
  //   email: 'marcella.russo@paprikut.name',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 8,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '8.jpg',
  //   name: 'Mccormick Ferguson',
  //   phone: '+8 (957) 553-2497',
  //   company: 'Waab',
  //   email: 'mccormick.ferguson@waab.info',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 9,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '9.jpg',
  //   name: 'Myrtle Walters',
  //   phone: '+29 (846) 562-3339',
  //   company: 'Hinway',
  //   email: 'myrtle.walters@hinway.me',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 10,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '10.jpg',
  //   name: 'Rachael Griffin',
  //   phone: '+26 (827) 437-3065',
  //   company: 'Callflex',
  //   email: 'rachael.griffin@callflex.net',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 11,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '11.jpg',
  //   name: 'Carney Emerson',
  //   phone: '+24 (821) 560-2716',
  //   company: 'Motovate',
  //   email: 'carney.emerson@motovate.ca',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 12,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '12.jpg',
  //   name: 'Katrina Cooke',
  //   phone: '+10 (904) 524-2485',
  //   company: 'Quarex',
  //   email: 'katrina.cooke@quarex.biz',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 13,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '13.jpg',
  //   name: 'Valdez Branch',
  //   phone: '+12 (941) 536-2874',
  //   company: 'Uniworld',
  //   email: 'valdez.branch@uniworld.com',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 14,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '14.jpg',
  //   name: 'Dejesus Rogers',
  //   phone: '+40 (806) 435-2871',
  //   company: 'Oronoko',
  //   email: 'dejesus.rogers@oronoko.biz',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 15,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '15.jpg',
  //   name: 'Horton Levy',
  //   phone: '+19 (932) 552-3976',
  //   company: 'Zensus',
  //   email: 'horton.levy@zensus.org',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 16,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '16.jpg',
  //   name: 'Ivy Mays',
  //   phone: '+15 (919) 430-3795',
  //   company: 'Letpro',
  //   email: 'ivy.mays@letpro.io',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 17,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '17.jpg',
  //   name: 'Butler Larsen',
  //   phone: '+18 (862) 489-2364',
  //   company: 'Micronaut',
  //   email: 'butler.larsen@micronaut.co.uk',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 18,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '18.jpg',
  //   name: 'Jimmie Clements',
  //   phone: '+34 (946) 451-3718',
  //   company: 'Sarasonic',
  //   email: 'jimmie.clements@sarasonic.tv',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 19,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '19.jpg',
  //   name: 'Kasey Rosa',
  //   phone: '+44 (910) 404-3456',
  //   company: 'Cytrak',
  //   email: 'kasey.rosa@cytrak.name',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 20,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '20.jpg',
  //   name: 'Emily Schneider',
  //   phone: '+40 (870) 564-3250',
  //   company: 'Noralex',
  //   email: 'emily.schneider@noralex.info',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 21,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '20.jpg',
  //   name: 'Lara Witt',
  //   phone: '+29 (971) 550-3793',
  //   company: 'Moltonic',
  //   email: 'lara.witt@moltonic.me',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 22,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '19.jpg',
  //   name: 'Hebert Bailey',
  //   phone: '+4 (884) 527-3408',
  //   company: 'Homelux',
  //   email: 'hebert.bailey@homelux.net',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 23,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '18.jpg',
  //   name: 'Lynda Garza',
  //   phone: '+44 (872) 404-3519',
  //   company: 'Quonk',
  //   email: 'lynda.garza@quonk.ca',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 24,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '17.jpg',
  //   name: 'Stark Fitzgerald',
  //   phone: '+7 (999) 438-2471',
  //   company: 'Farmage',
  //   email: 'stark.fitzgerald@farmage.biz',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 25,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '16.jpg',
  //   name: 'Karla Atkins',
  //   phone: '+44 (869) 502-3263',
  //   company: 'Kiggle',
  //   email: 'karla.atkins@kiggle.com',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 26,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '15.jpg',
  //   name: 'Wilkerson Beasley',
  //   phone: '+29 (889) 462-3929',
  //   company: 'Naxdis',
  //   email: 'wilkerson.beasley@naxdis.biz',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 27,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '14.jpg',
  //   name: 'Webb Stark',
  //   phone: '+38 (864) 482-2108',
  //   company: 'Equitax',
  //   email: 'webb.stark@equitax.org',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 28,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '13.jpg',
  //   name: 'Courtney Barrett',
  //   phone: '+6 (828) 438-3288',
  //   company: 'Magnemo',
  //   email: 'courtney.barrett@magnemo.io',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 29,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '12.jpg',
  //   name: 'Joanne Anderson',
  //   phone: '+27 (994) 435-2952',
  //   company: 'Extragene',
  //   email: 'joanne.anderson@extragene.co.uk',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 30,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '11.jpg',
  //   name: 'Hampton Golden',
  //   phone: '+1 (967) 549-3561',
  //   company: 'Accupharm',
  //   email: 'hampton.golden@accupharm.tv',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 31,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '10.jpg',
  //   name: 'Becker Mejia',
  //   phone: '+16 (971) 587-3994',
  //   company: 'Zillacom',
  //   email: 'becker.mejia@zillacom.name',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 32,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '9.jpg',
  //   name: 'Marks Benton',
  //   phone: '+25 (961) 567-2085',
  //   company: 'Emoltra',
  //   email: 'marks.benton@emoltra.info',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 33,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '8.jpg',
  //   name: 'Mai Wade',
  //   phone: '+23 (970) 419-3433',
  //   company: 'Senmao',
  //   email: 'mai.wade@senmao.me',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 34,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '7.jpg',
  //   name: 'Maldonado Moran',
  //   phone: '+6 (912) 523-3028',
  //   company: 'Autograte',
  //   email: 'maldonado.moran@autograte.net',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 35,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '6.jpg',
  //   name: 'Jaclyn Maxwell',
  //   phone: '+39 (988) 424-2719',
  //   company: 'Ecratic',
  //   email: 'jaclyn.maxwell@ecratic.ca',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 36,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '5.jpg',
  //   name: 'Carmela Barron',
  //   phone: '+44 (932) 469-3459',
  //   company: 'Xinware',
  //   email: 'carmela.barron@xinware.biz',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 37,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '4.jpg',
  //   name: 'Araceli Velasquez',
  //   phone: '+43 (965) 579-3919',
  //   company: 'Capscreen',
  //   email: 'araceli.velasquez@capscreen.com',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 38,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '3.jpg',
  //   name: 'Gonzales Randolph',
  //   phone: '+23 (951) 574-3150',
  //   company: 'Ronbert',
  //   email: 'gonzales.randolph@ronbert.biz',
  //   selected: false,
  //   starred: true
  // },
  // {
  //   id: 39,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '2.jpg',
  //   name: 'Ramirez Burt',
  //   phone: '+24 (963) 484-3465',
  //   company: 'Quizmo',
  //   email: 'ramirez.burt@quizmo.org',
  //   selected: false,
  //   starred: false
  // },
  // {
  //   id: 40,
  //   imageSrc: myimagePath[0]['path'] + myimagePath[0]['avatars'] +  '1.jpg',
  //   name: 'Jessica Frenz',
  //   phone: '+49 (332) 45-4234',
  //   company: 'Bongo',
  //   email: 'jessica.frenz@bongo.com',
  //   selected: false,
  //   starred: false
  // }
];
