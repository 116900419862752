import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import Pusher from 'pusher-js' ; 
import { HttpClient } from '@angular/common/http';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,
              private API:BackendApiService,
              private router:Router,
              private http:HttpClient) {}


 username ='username';
message='';
messages=[];


  ngOnInit() {

  //  Pusher.logToConsole=true;
    const pusher = new Pusher('4809fa21bf1d8902814b',{
      cluster: 'eu'
    });
    let token = localStorage.getItem('token'); 
    const channel = pusher.subscribe('challange.' + token    );
    channel.bind('message', data=> {
      this.messages.push(data);
      
      if (confirm(' هناك شخص يدعوك للمنافسة في تحدي المنهج هل توافق على التحدي ؟')) {
        // Save it!
        alert(data.message) ; 
        console.log('Thing was saved to the database.');
      } else {
        // Do nothing!
        console.log('Thing was not saved to the database.');
      }


    });




  }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
function openDialog() {
  throw new Error('Function not implemented.');
}

