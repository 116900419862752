import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef ,private tokenService:TokenService) { }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
    sessionStorage.clear();
    localStorage.clear();

   window.location.href = 'http://localhost:4200/login'  ;

   this.tokenService.remove;
  }
}
