<ngx-spinner type="pacman"    >



  <div class="loader"></div>


</ngx-spinner>






<ng-template #sidenavRef>
  <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>


<!-- <ng-template class="card flex-auto mr-4" style="border: 1px dotted #ccc; padding: 8px; height: 600px; overflow: scroll; width: 30%;">
  <h2 class="matCardTitle">
    <mat-icon class="mr-1" svgIcon="mat:assignment"></mat-icon>
    Diagnosis Interface
  </h2>
  <LIBYANA-departments  ></LIBYANA-departments>
</ng-template> -->


<ng-template #toolbarRef>
  <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async" [mobileQuery]="!(isDesktop$ | async)" class="vex-toolbar">
    <LIBYANA-departments  ></LIBYANA-departments>
  </vex-toolbar>
</ng-template>

<ng-template #footerRef>
  <vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></vex-footer>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

    <!-- Left Sidebar for Departments -->
 



<vex-layout [footerRef]="footerRef" [quickpanelRef]="quickpanelRef" [sidenavRef]="sidenavRef" [toolbarRef]="toolbarRef">
 
</vex-layout>
<!-- تفعيل زر عائم  -->
<!-- <vex-config-panel-toggle (openConfig)="configpanel.open()"></vex-config-panel-toggle> -->

<!-- CONFIGPANEL -->
<vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">


  <vex-config-panel></vex-config-panel>
</vex-sidebar>
<!-- END CONFIGPANEL -->
