import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { Plan, leaves } from 'src/assets/models/employees.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeavesService {
  baseUrl = environment.apiUrl+'/leaves';
    addLevesPatch(data: any): Observable<any> {
      const formData = new FormData();
      formData.append('AddToData', data.AddToData);
      formData.append('file', data.file);
      formData.append('uploadfile', data.uploadfile);

      
      return this.http.post(this.baseUrl + '/import', formData)
          .pipe(
              map(response => {
                  this.toastr.success('تم أستجلباب الايجازات بنجاح', 'Success');
                  return response;
              }),
              catchError(error => {
                  this.toastr.error('فشل اضافة الايجازات راجع فورمات', 'Error');
                  return of(null);
              })
          );
  }
  addLeave(leaveData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}`, leaveData).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error adding leave', error);
        return throwError(() => new Error('Failed to add leave. Please try again later.'));
      })
    );
  }

  private apiUrl = environment.apiUrl;
  private empssSource = new BehaviorSubject<leaves[]>([]);

  constructor(private http: HttpClient ,private toastr:ToastrService) {}

  getleavess(): Observable<leaves[]> {
    return this.http.get<leaves[]>(this.baseUrl).pipe(
      tap(surveys => {
        console.log(surveys)
        // تحديث الـ BehaviorSubject بالاستبيانات الجديدة
        this.empssSource.next(surveys);
      })
    );
  }



  deleteLeave(id: string)  {
    return this.http.delete(`${this.baseUrl}/${id}`).pipe(
      map(response => {
        this.toastr.success('تم حذف الإجازة بنجاح', 'Success');
        return response;
      }),
      catchError(error => {
        this.toastr.error('فشل في حذف الإجازة. حاول مرة أخرى لاحقًا.', 'Error');
        return throwError(() => new Error('Failed to delete leave. Please try again later.'));
      })
    );
  }

  updateLeave(id: string, leaveData: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, leaveData).pipe(
      map(response => {
        this.toastr.success('تم تحديث الإجازة بنجاح', 'Success');
        return response;
      }),
      catchError(error => {
        this.toastr.error('فشل في تحديث الإجازة. حاول مرة أخرى لاحقًا.', 'Error');
        return throwError(() => new Error('Failed to update leave. Please try again later.'));
      })
    );
  }
}

