<mat-nav-list>
  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon mat-list-icon svgIcon="logo:gmail"></mat-icon>
    <span mat-line>Send with Gmail</span>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon mat-list-icon svgIcon="logo:facebook"></mat-icon>
    <span mat-line>Post on Facebook</span>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon mat-list-icon svgIcon="logo:twitter"></mat-icon>
    <span mat-line>Share on Twitter</span>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon mat-list-icon svgIcon="logo:whatsapp"></mat-icon>
    <span mat-line>Share on WhatsApp</span>
  </a>
</mat-nav-list>
