import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { take } from 'rxjs';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective {
  @Input() set appHasRole(requiredRoles: string[]) {
    // If no roles are provided, render the content by default
    if (!requiredRoles || requiredRoles.length === 0 || requiredRoles ===undefined ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.checkRoles(requiredRoles);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private accountService: UserService,             
    private toastr: ToastrService // Inject the ToastrService
  ) {}

  private checkRoles(requiredRoles: string[]) {


    
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => {
   console.log(requiredRoles)
      console.log(user)
      if (user && user.roles && user.roles.some(role => requiredRoles.includes(role))) {
        // User has at least one of the required roles, so render the content
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
       // this.navigationService.items = this.navigationService.items;

      } else {
        // User doesn't have any of the required roles, so clear the view
        this.viewContainer.clear();
        return;
        // Display error toastr
      //  this.toastr.error('You do not have the required '+requiredRoles, 'Access Denied');
      }
    });
  }
}
