import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { TokenceptorService } from './services/tokenceptor.service';
import { LoadingInterceptor } from 'src/assets/loadingSys/loading.interceptors';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PreloadingStrategy } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { TopicFormComponent } from './topic-form/topic-form.component';
import { OverTimeOrderComponent } from './over-time-order/over-time-order.component';
import { SharedModule } from './ShareComponent/shared.module';
import { UserService } from './user/user.service';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { DateAdapter } from 'angular-calendar';
import { CustomDateAdapter } from './CustomDateAdapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_ADAPTER_OPTIONS_FACTORY, MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [AppComponent, TopicFormComponent, OverTimeOrderComponent 

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,BrowserAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    MatDatepickerModule, MatMomentDateModule,
      CustomLayoutModule,
    FormsModule, // Add FormsModule here
    MatSnackBarModule,
    ToastrModule.forRoot(
      { timeOut: 3500,
       positionClass: 'toast-bottom-center',
       preventDuplicates: true,}
     )
  ],
    providers: [
 
 
 
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS_FACTORY, useValue: { useUtc: true } },
     
  // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  // ,{ provide: PreloadingStrategy, useClass: QuicklinkStrategy },

     { provide: HTTP_INTERCEPTORS,
      useClass: TokenceptorService,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    {provide:HTTP_INTERCEPTORS,useClass: ErrorInterceptor,multi:true},
     { provide: PreloadingStrategy, useClass: QuicklinkStrategy },
     // Include the ErrorService in the providers
     {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
     {
       provide: DateAdapter,
       useClass: MomentDateAdapter,
       deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
     },
     {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}


 
