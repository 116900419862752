import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { OverTimeOrder } from 'src/assets/models/employees.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OverTimeOrderService {

    private apiUrl = environment.apiUrl;
    private empssSource = new BehaviorSubject<OverTimeOrder[]>([]);

    constructor(private http: HttpClient) {}

    getOverTimeOrder(): Observable<OverTimeOrder[]> {
      return this.http.get<OverTimeOrder[]>(this.apiUrl+'/OverTimeOrder').pipe(
        tap(surveys => {
          console.log(surveys)
          // تحديث الـ BehaviorSubject بالاستبيانات الجديدة
          this.empssSource.next(surveys);
        })
      );
    }
  }
