// map.component.ts
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map-component.html',
  styleUrls: ['./map-component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  @Output() locationSelected = new EventEmitter<{ latitude: string, longitude: string }>();

  private map: L.Map;
  private marker: L.Marker;

  constructor() { }

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {
    // Initialize the map with a center in Libya
    this.map = L.map(this.mapElement.nativeElement).setView([26.3351, 17.2283], 5); // Libya coordinates and zoom level

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(this.map);

    this.map.on('click', (event: L.LeafletMouseEvent) => {
      this.updateMarker(event.latlng.lat, event.latlng.lng);
    });
  }

  private updateMarker(lat: string, lng: string): void {
    if (!this.marker) {
      this.marker = L.marker([lat, lng]).addTo(this.map);
    } else {
      this.marker.setLatLng([lat, lng]);
    }

    this.locationSelected.emit({ latitude: lat, longitude: lng });
  }
}
