import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Duration } from 'luxon';
import { EmployeesService } from 'src/app/employees/employees.service';
import { SnackbarService } from 'src/app/snackbarService';
import { Emp, leaves } from 'src/assets/models/employees.model';
import { LeavesService } from '../leaves.service';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'LIBYANA-add-leaves',
  templateUrl: './add-leaves.component.html',
  styleUrls: ['./add-leaves.component.scss']
})
export class AddLeavesComponent implements OnInit {

  leaveForm: FormGroup;
  employee: Emp | null = null;

  constructor(private fb: FormBuilder, private employeeService: EmployeesService ,private snackBar:SnackbarService 
,private leavesService:LeavesService ,     private dialogRef: MatDialogRef<AddLeavesComponent>,


  ) {}
  onFileCardSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      
      // Set the file directly in the form control
      this.leaveForm.get('uploadfile')?.setValue(file);
  
      // Optional: Display a preview if needed
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const previewImg = document.getElementById('logoPreview') as HTMLImageElement;
        if (previewImg) {
          previewImg.src = e.target.result;
        }
      };
      reader.readAsDataURL(file); // Read the file as a base64 URL for preview
    }
  }
  
  ngOnInit(): void {
    this.leaveForm = this.fb.group({    
       empId: ['', Validators.required],
       type: ['', Validators.required],
       from: ['', Validators.required],
       noOfDays: ['', Validators.required],
       reason: [''],
       deductFromBalance:[false],
       uploadfile:[]
    });
  }


  
  
  submit(): void {
    if (this.leaveForm.valid) {
      const formData = new FormData();
  
      // Loop through form controls
      Object.keys(this.leaveForm.controls).forEach(key => {
        const value = this.leaveForm.get(key)?.value;
  
        // Check if the value is a file
        if (key === 'uploadfile' && value instanceof File) {
          formData.append(key, value); 
        } else if (value !== null && value !== undefined) {
          formData.append(key, value); 
        }
      });
  
      // Debug: Log formData entries for verification
      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });
  
      // Send the form data
      this.leavesService.addLeave(formData).subscribe(
        response => {
     
          this.snackBar.open('Leave added successfully.');
          this.dialogRef.close(response);
        },
        error => {
          console.error('Error adding leave', error);
          this.snackBar.open('Failed to add leave.');
        }
      );
    } else {
      console.error('Form is invalid');
      this.snackBar.open('Please fill in all required fields.');
    }
  }
  
  
  search = new FormControl ;

  onNoClick(): void {
    // Handle cancel action
    this.dialogRef.close();
  }

  searchEmployee() {
    const empId = this.search?.value;
    if (empId) {
      this.employeeService.searchEmployee(empId).subscribe(
        (data: Emp) => {
          this.employee = data;
          this.leaveForm.patchValue({
            empId: data.id // Assuming `fullName` is a property of `EmpToReturn`
          });
        },
        (error) => {
          console.error('Error fetching employee data', error);
        }
      );
    }
  }
}