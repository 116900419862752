// // import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
// // import { Injectable } from "@angular/core";
// // import { Router } from "@angular/router";


// // import { Console } from "console";
// // import { catchError, map, Observable, of, ReplaySubject, take, throwError } from "rxjs";
// // import {
// //   Address,
// //   IAddress,
// //   IFriend,
// //   IGrade,
// //   IMember,
// //   imgToAdd,
// //   imgToEdith,
// //   IPhoto,
// //   ISchool,
// //   IUser,
// //   IUserNew,
// //   Photo,
// // } from "src/assets/models/user";
// // import { TokenService } from "../services/token.service";
// // import { environment } from "src/environments/environment";
// // import { ToastrService } from "ngx-toastr";

// // @Injectable({
// //   providedIn: "root",
// // })
// // export class UserService {
// //   blockedSite(name: string) {
// //   //change steate sdite to sinmbute 3 or reomve black to chag 1


// //   }

// //   private currentUserSources = new ReplaySubject<IUserNew>(1);
// //   currentUser$ = this.currentUserSources.asObservable();
// //   private UserPhotoCover = new ReplaySubject<string>(1);
// //   currentUserPhotoCover$ = this.UserPhotoCover.asObservable();
// //   private UserPhotoAvatar = new ReplaySubject<string>(1);
// //   currentUserPhotoAvatar$ = this.UserPhotoAvatar.asObservable();
// //   baseUrl = environment.apiUrl;
// //   constructor(
// //     private http: HttpClient,
// //     private route: Router,
// //     private tokenService: TokenService, private toastr:ToastrService
// //   ) {}



// //   login(value: any) {
// //     return this.http.post<IUserNew>(this.baseUrl + "account/login", value).pipe(
// //       map((user: IUserNew) => {
// //         if (user) {
// //           localStorage.setItem("token",user.token);
// //           this.currentUserSources.next(user);
// //         }
// //       })
// //     );
// //   }
// //   register(value: any) {
// //     return this.http.post<IUserNew>(this.baseUrl + "account/register", value).pipe(
// //       map((user: IUserNew) => {
// //         if (user) {
// //           this.currentUserSources.next(user);
// //         }
// //       })
// //     );
// //   }

// //   checkEmailExists(email: string) {
// //     return this.http.get(this.baseUrl + "/Account/emailexists?email=" + email);
// //   }


 

// //   loadCurrentUser(): Observable<IUserNew | null> {

// //    var token =  localStorage.getItem("token");

// //     return this.http.get<IUserNew>(this.baseUrl + "/account").pipe(
// //       map((user) => {
// //         console.log(user);
// //         this.currentUserSources.next(user); 
// //         return user;
// //       }),
// //       catchError((error) => {
// //         this.toastr.error('An error occurred during login.', 'Error');
// //           this.logout();
// //         return of(null);
// //       })
// //     );
// //   }


// //   logout() {
// //     this.currentUserSources.next(null);
// //     localStorage.removeItem("token");
// //     this.route.navigateByUrl("/login");
// //   }

// //   getUserAddress() {
// //     return this.http.get<IAddress>(this.baseUrl + "account/address");
// //   }

// //   updateUserAddress(address: IAddress) {
// //     return this.http.put(this.baseUrl + "account/address", address);
// //   }

// //   setUpdateCurrentUser(user: any) {
// //     this.currentUserSources.next(user);
// //   }

// //   setUpdateCurrentPhotoCover(photo: any) {
// //     console.log(photo);
// //     this.UserPhotoCover.next(photo);
// //   }

// //   setUpdateCurrentPhotoAvatar(photo: any) {
// //     console.log(photo);
// //     this.UserPhotoAvatar.next(photo);
// //   }

// //   loadSchool() {
// //     return this.http
// //       .get<ISchool[]>(
// //         this.baseUrl + "/schools"
// //       )
// //       .pipe(take(1));
// //   }
// //   loadAddress() {
// //     return this.http
// //       .get<Address[]>(
// //         this.baseUrl + "​/Account​/address"
// //       )
// //       .pipe(map((rep) => rep));
// //   }
// //   updateUser(user: any) {
// //     console.log("update" + user);
// //     this.http
// //       .put(this.baseUrl + "/user", user)
// //       .subscribe((rep) => {
// //         this.loadCurrentUser();
// //         console.log(rep);
// //       });
// //   }

// //   loadGrade() {
// //     return this.http
// //       .get<IGrade[]>(
// //         this.baseUrl + "/grades"
// //       )
// //       .pipe(map((rep) => rep));
// //   }

// //   getFriends() {
// //     return this.http
// //       .get<IFriend[]>(
// //         this.baseUrl + "/friends"
// //       )
// //       .pipe(map((rep) => rep));
// //   }
// //   addFrindes(member: IMember) {
// //     const data = {
// //       friend_user_id: member.id,
// //     };
// //     console.log(data);
// //     return this.http
// //       .post(
// //         this.baseUrl + "/friend_request",
// //         data
// //       )
// //       .subscribe((rep) => {
// //         console.log(rep);

// //         rep;});
// //   }
// //   blockedMember(contact: number) {
// //     const data = {
// //       blocked_user_id: contact,
// //     };
// //     console.log(data);
// //     return this.http
// //       .post(
// //         this.baseUrl + "/member_block",
// //         data
// //       )
// //       .subscribe((rep) => {
// //         console.log(rep);

// //         rep;});
// //   }

// //   unblockedMember(contact: number) {
// //     const data = {
// //       blocked_user_id: contact,
// //     };
// //     console.log(data);
// //     return this.http
// //       .delete(
// //         this.baseUrl + "/member_block/"+contact,

// //       )
// //       .subscribe((rep) => {
// //         console.log(rep);

// //         rep;});
// //   }




// //   acceptFriendship(friend: number) {

// //     const data = {
// //       friend_user_id: friend,
// //     };

// //     return this.http
// //       .post(
// //         "https://localhost:5001/api/friend_request",
// //         data
// //       )
// //       .subscribe((rep) => {
// //         console.log(rep);

// //         rep;});
// //   }

// //   deleteFriends(friend: number) {
// //     console.log('-delete freinde'+friend);
// //     return this.http
// //       .delete(
// //         "https://localhost:5001/api/friend/"+friend);


// //   }
// //   AbrveFrindes(friend: number) {

// //     const data = {friend_user_id : friend};
// //     return this.http
// //       .put(
// //         "https://localhost:5001/api/friend_approve",
// //         data
// //       )
// //       .subscribe((rep) => {
// //         console.log(rep);

// //         rep;});
// //   }

// //   loadImages() {
// //     return this.http
// //       .get<IPhoto[]>(
// //         "https://localhost:5001/api/user_images"
// //       )
// //       .pipe(
// //         map((rep) => {
// //           return rep["data"];
// //         })
// //       );
// //   }
// //   uploadImage(img: imgToAdd) {
// //     this.http
// //       .post(
// //         "https://localhost:5001/api/user_image",
// //         img
// //       )
// //       .subscribe((rep) => {
// //         console.log(rep);

// //         rep;});
// //   }
// //   UpdateImg(img: imgToEdith) {
// //     this.http
// //       .put("https://localhost:5001/api/user_image", img)
// //       .subscribe((rep) => {
// //         console.log(rep);

// //         rep;});
// //   }

// //   DeleteImg(img: Photo) {
// //     return this.http
// //       .delete(
// //         "https://localhost:5001/api/User/delete-photo/"+img.id)
// //       .pipe(map((rep) => rep));
// //   }
// // }
// import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
// import { Injectable } from "@angular/core";
// import { Router } from "@angular/router";
// import { catchError, map, Observable, of, ReplaySubject, switchMap, take, throwError } from "rxjs";
// import {
//   Address,
//   IAddress,
//   IFriend,
//   IGrade,
//   IMember,
//   imgToAdd,
//   imgToEdith,
//   IPhoto,
//   ISchool,
//   IUser,
//   IUserNew,
//   Photo,
// } from "src/assets/models/user";
// import { TokenService } from "../services/token.service";
// import { environment } from "src/environments/environment";
// import { ToastrService } from "ngx-toastr";

// @Injectable({
//   providedIn: "root",
// })
// export class UserService {

//   private currentUserSources = new ReplaySubject<IUserNew>(1); // Correct type and buffer size
//   currentUser$ = this.currentUserSources.asObservable();
//   private UserPhotoCover = new ReplaySubject<string>(1);
//   currentUserPhotoCover$ = this.UserPhotoCover.asObservable();
//   private UserPhotoAvatar = new ReplaySubject<string>(1);
//   currentUserPhotoAvatar$ = this.UserPhotoAvatar.asObservable();
//   baseUrl = environment.apiUrl;

//   constructor(
//     private http: HttpClient,
//     private route: Router,
//     private tokenService: TokenService, 
//     private toastr: ToastrService
//   ) {}

//   login(value: any) {
//     return this.http.post<IUserNew>(this.baseUrl + "/account/login", value).pipe(
//       map((user: IUserNew) => {
//         if (user) {
//           localStorage.setItem("token", user.token);
//           this.currentUserSources.next(user);
//         }
//       }),
//       catchError((error: HttpErrorResponse) => {
//         this.toastr.error('Login failed.', 'Error');
//         return throwError(error);
//       })
//     );
//   }

//   register(value: any) {
//     return this.http.post<IUserNew>(this.baseUrl + "/account/register", value).pipe(
//       map((user: IUserNew) => {
//         if (user) {
//           this.currentUserSources.next(user);
//         }
//       }),
//       catchError((error: HttpErrorResponse) => {
//         this.toastr.error('Registration failed.', 'Error');
//         return throwError(error);
//       })
//     );
//   }

//   checkEmailExists(email: string) {
//     return this.http.get(this.baseUrl + "/Account/emailexists?email=" + email);
//   }
//   loadCurrentUser(): Observable<IUserNew | null> {

//     console.log('call user')
//     return this.currentUser$.pipe(
//       take(1), // Take the first value emitted by the currentUser$ observable
//       switchMap((user) => {
//         if (user) {
//           console.log('call hhh user) user')

//           // If there is already a user, return it
//           return of(user);
//         } else {
//           console.log('call  of(user) user')
//           // If no user, load from API
//           return this.http.get<IUserNew>(this.baseUrl + "/account").pipe(
//             map((user) => {
//               console.log('++++call ++account user')
//               this.currentUserSources.next(user);
//               return user;
//             }),
//             catchError((error) => {
//               this.toastr.error('An error occurred during user load.', 'Error');
//               this.logout();
//               return of(null);
//             })
//           );
//         }
//       })
//     );
//   }
  
// getCusrrentuser()
// {
//   return this.http.get<IUserNew>(this.baseUrl + "/account").pipe(
//     map((user) => {
//       console.log('++++call ++account user')
//       this.currentUserSources.next(user);
//       return user;
//     }),
//     catchError((error) => {
//       this.route.navigateByUrl('/login')
//       this.toastr.error('An error occurred during user load.', 'Error');
//       this.logout();
      
//       return of(null);
//     }))
// }


//   loadCurrentUserzz(): Observable<any | null> {
//     const token = localStorage.getItem("token");
//     return this.http.get<any>(this.baseUrl + "/account").pipe(
//       map((user) => {
//         this.currentUserSources.next(user); 
//         return user;
//       }),
//       catchError((error) => {
//         this.toastr.error('An error occurred during user load.', 'Error');
//         this.logout();
//         return of(null);
//       })
//     );
//   }

//   logout() {
//     this.currentUserSources.next(null);
//     localStorage.removeItem("token");
//     this.route.navigateByUrl("/login");
//   }

//   getUserAddress() {
//     return this.http.get<IAddress>(this.baseUrl + "account/address");
//   }

//   updateUserAddress(address: IAddress) {
//     return this.http.put(this.baseUrl + "account/address", address);
//   }

//   setUpdateCurrentUser(user: any) {
//     this.currentUserSources.next(user);
//   }

//   setUpdateCurrentPhotoCover(photo: any) {
//     this.UserPhotoCover.next(photo);
//   }

//   setUpdateCurrentPhotoAvatar(photo: any) {
//     this.UserPhotoAvatar.next(photo);
//   }

//   loadSchool() {
//     return this.http
//       .get<ISchool[]>(this.baseUrl + "/schools")
//       .pipe(take(1));
//   }

//   loadAddress() {
//     return this.http
//       .get<Address[]>(this.baseUrl + "/Account/address")
//       .pipe(map((rep) => rep));
//   }

//   updateUser(user: any) {
//     this.http
//       .put(this.baseUrl + "/user", user)
//       .subscribe((rep) => {
//         this.loadCurrentUser().subscribe();
//       });
//   }

//   loadGrade() {
//     return this.http
//       .get<IGrade[]>(this.baseUrl + "/grades")
//       .pipe(map((rep) => rep));
//   }

//   getFriends() {
//     return this.http
//       .get<IFriend[]>(this.baseUrl + "/friends")
//       .pipe(map((rep) => rep));
//   }

//   addFriends(member: IMember) {
//     const data = {
//       friend_user_id: member.id,
//     };
//     return this.http
//       .post(this.baseUrl + "/friend_request", data)
//       .subscribe((rep) => {
//         console.log(rep);
//       });
//   }

//   blockedMember(contact: number) {
//     const data = {
//       blocked_user_id: contact,
//     };
//     return this.http
//       .post(this.baseUrl + "/member_block", data)
//       .subscribe((rep) => {
//         console.log(rep);
//       });
//   }

//   unblockedMember(contact: number) {
//     return this.http
//       .delete(this.baseUrl + "/member_block/" + contact)
//       .subscribe((rep) => {
//         console.log(rep);
//       });
//   }

//   acceptFriendship(friend: number) {
//     const data = {
//       friend_user_id: friend,
//     };
//     return this.http
//       .post(this.baseUrl + "/friend_request", data)
//       .subscribe((rep) => {
//         console.log(rep);
//       });
//   }

//   deleteFriends(friend: number) {
//     return this.http
//       .delete(this.baseUrl + "/friend/" + friend);
//   }

//   approveFriends(friend: number) {
//     const data = { friend_user_id: friend };
//     return this.http
//       .put(this.baseUrl + "/friend_approve", data)
//       .subscribe((rep) => {
//         console.log(rep);
//       });
//   }

//   loadImages() {
//     return this.http
//       .get<IPhoto[]>(this.baseUrl + "/user_images")
//       .pipe(map((rep) => rep["data"]));
//   }

//   uploadImage(img: imgToAdd) {
//     return this.http
//       .post(this.baseUrl + "/user_image", img)
//       .subscribe((rep) => {
//         console.log(rep);
//       });
//   }

//   updateImg(img: imgToEdith) {
//     return this.http
//       .put(this.baseUrl + "/user_image", img)
//       .subscribe((rep) => {
//         console.log(rep);
//       });
//   }

//   deleteImg(img: Photo) {
//     return this.http
//       .delete(this.baseUrl + "/User/delete-photo/" + img.id)
//       .pipe(map((rep) => rep));
//   }
// }
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map, Observable, of, ReplaySubject, switchMap, take, throwError } from "rxjs";
import {
  Address,
  IAddress,
  IFriend,
  IGrade,
  IMember,
  imgToAdd,
  imgToEdith,
  IPhoto,
  ISchool,
  IUser,
  IUserNew,
  Photo,
} from "src/assets/models/user";
import { TokenService } from "../services/token.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private currentUserSources = new ReplaySubject<IUserNew>(1);
  currentUser$ = this.currentUserSources.asObservable();
  private UserPhotoCover = new ReplaySubject<string>(1);
  currentUserPhotoCover$ = this.UserPhotoCover.asObservable();
  private UserPhotoAvatar = new ReplaySubject<string>(1);
  currentUserPhotoAvatar$ = this.UserPhotoAvatar.asObservable();
  baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private route: Router,
    private tokenService: TokenService, 
    private toastr: ToastrService
  ) {}

  login(value: any) {
    return this.http.post<IUserNew>(`${this.baseUrl}/account/login`, value).pipe(
      map((user: IUserNew) => {
        if (user) {
          localStorage.setItem("token", user.token);
          this.currentUserSources.next(user);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastr.error('Login failed.', 'Error');
        return throwError(() => error);
      })
    );
  }

  register(value: any) {
    return this.http.post<IUserNew>(`${this.baseUrl}/account/register`, value).pipe(
      map((user: IUserNew) => {
        if (user) {
          this.currentUserSources.next(user);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastr.error('Registration failed.', 'Error');
        return throwError(() => error);
      })
    );
  }

  checkEmailExists(email: string) {
    return this.http.get(`${this.baseUrl}/Account/emailexists?email=${email}`);
  }

  loadCurrentUser(): Observable<IUserNew | null> {
    return this.currentUser$.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          return of(user);
        } else {
          return this.http.get<IUserNew>(`${this.baseUrl}/account`).pipe(
            map((user) => {
              this.currentUserSources.next(user);
              return user;
            }),
            catchError((error) => {
              this.toastr.error('An error occurred during user load.', 'Error');
              this.logout();
              return of(null);
            })
          );
        }
      })
    );
  }

  getCurrentUser(): Observable<IUserNew | null> {
    return this.http.get<IUserNew>(`${this.baseUrl}/account`).pipe(
      map((user) => {
        this.currentUserSources.next(user);
        return user;
      }),
      catchError((error) => {
        this.toastr.error('An error occurred during user load.', 'Error');
        this.logout();
        return of(null);
      })
    );
  }

  logout() {
    this.currentUserSources.next(null);
    localStorage.removeItem("token");
    this.route.navigateByUrl("/login");
  }

  getUserAddress() {
    return this.http.get<IAddress>(`${this.baseUrl}/account/address`);
  }

  updateUserAddress(address: IAddress) {
    return this.http.put(`${this.baseUrl}/account/address`, address);
  }

  setUpdateCurrentUser(user: IUserNew) {
    this.currentUserSources.next(user);
  }

  setUpdateCurrentPhotoCover(photo: string) {
    this.UserPhotoCover.next(photo);
  }

  setUpdateCurrentPhotoAvatar(photo: string) {
    this.UserPhotoAvatar.next(photo);
  }

  loadSchool() {
    return this.http.get<ISchool[]>(`${this.baseUrl}/schools`).pipe(take(1));
  }

  loadAddress() {
    return this.http.get<Address[]>(`${this.baseUrl}/Account/address`).pipe(map((rep) => rep));
  }

  updateUser(user: any) {
    return this.http.put(`${this.baseUrl}/user`, user).subscribe(() => {
      this.loadCurrentUser().subscribe();
    });
  }

  loadGrade() {
    return this.http.get<IGrade[]>(`${this.baseUrl}/grades`).pipe(map((rep) => rep));
  }

  getFriends() {
    return this.http.get<IFriend[]>(`${this.baseUrl}/friends`).pipe(map((rep) => rep));
  }

  addFriend(member: IMember) {
    const data = { friend_user_id: member.id };
    return this.http.post(`${this.baseUrl}/friend_request`, data).subscribe((rep) => {
      console.log(rep);
    });
  }

  blockMember(contact: number) {
    const data = { blocked_user_id: contact };
    return this.http.post(`${this.baseUrl}/member_block`, data).subscribe((rep) => {
      console.log(rep);
    });
  }

  unblockMember(contact: number) {
    return this.http.delete(`${this.baseUrl}/member_block/${contact}`).subscribe((rep) => {
      console.log(rep);
    });
  }

  acceptFriendship(friend: number) {
    const data = { friend_user_id: friend };
    return this.http.post(`${this.baseUrl}/friend_request`, data).subscribe((rep) => {
      console.log(rep);
    });
  }

  deleteFriend(friend: number) {
    return this.http.delete(`${this.baseUrl}/friend/${friend}`).subscribe((rep) => {
      console.log(rep);
    });
  }

  approveFriend(friend: number) {
    const data = { friend_user_id: friend };
    return this.http.put(`${this.baseUrl}/friend_approve`, data).subscribe((rep) => {
      console.log(rep);
    });
  }

  loadImages() {
    return this.http.get<IPhoto[]>(`${this.baseUrl}/user_images`).pipe(
      map((rep) => rep["data"])
    );
  }

  uploadImage(img: imgToAdd) {
    return this.http.post(`${this.baseUrl}/user_image`, img).subscribe((rep) => {
      console.log(rep);
    });
  }

  updateImage(img: imgToEdith) {
    return this.http.put(`${this.baseUrl}/user_image`, img).subscribe((rep) => {
      console.log(rep);
    });
  }

  deleteImage(img: Photo) {
    return this.http.delete(`${this.baseUrl}/User/delete-photo/${img.id}`).pipe(map((rep) => rep));
  }
}
