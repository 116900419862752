import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { UserService } from './user/user.service';
import { environment } from 'src/environments/environment';
import { filter, identity, pipe, take } from 'rxjs';
import { TokenService } from './services/token.service';
import { DepartmentsService } from './departments/departments.service';
import { error } from 'console';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer,private  departmentService:DepartmentsService,
              public userService:UserService,
             private router: Router,
              private token :TokenService) {
              
                    this.userService.getCurrentUser().subscribe( (rep)=>{
                      if(rep)
                     { this.departmentService.getDepartmentHierarchy().subscribe(data => {
            
                      });  } 

                      error: () => this.router.navigateByUrl('/login')
                    },error=>{
                      this.router.navigateByUrl('/login')
                    });
                 
             

                           
                this.router.events
                  .pipe(filter(event => event instanceof NavigationStart))
                  .subscribe((event: NavigationStart) => {
                    if (event.url !== '/login') {
                      document.body.style.backgroundImage = 'none';
                      document.body.style.opacity = '2';


                    }else {
                      document.body.style.backgroundImage = "'url('assets/img/layouts/HomePage.jpg')";

                      document.body.style.opacity = '0.9';
                    }
                  });

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */

    this.navigationService.items =
    [
          {
          type: 'subheading',
          label: 'التطبيقات',
          children: [


            {
              type: 'link',
              label: 'الملف الشخصي',
              route: '/user',
              icon: 'mat:person_outline' , roles:["Admin","memeber","HR_Admin"]
            },
            {
              type: 'link',
              label: 'الموظفين',
              route: '/emp',
              icon: 'mat:contacts' ,roles :["System_Administrator","HR_Admin","HR_Editor","HR_Viewer","Coordinator"]
            },
            {
              type: 'link',
              label: 'الادارات',
              route: '/dep',
              icon: 'mat:business',roles :["System_Administrator","HR_Admin","HR_Editor","HR_Viewer","Member"]
            },      {
              type: 'link',
              label: 'خطط العمل',
              route: '/plan',
              icon: 'mat:chrome_reader_mode',roles :["System_Administrator","HR_Admin","HR_Editor","Member"]
            },
            {
              type: 'link',
              label: 'العطل الرسميه ',
              route: '/holiday',
              icon: 'mat:weekend',roles :["System_Administrator","HR_Admin","HR_Editor"]
            },  
                {
              type: 'link',
              label: 'العقوبات',
              route: '/penalties',
              icon: 'mat:balance',roles :["System_Administrator","HR_Admin"]
            },
            {
              type: 'link',
              label: 'الايجازات ',
              route: '/leaves',
              icon: 'mat:next_week',roles :["System_Administrator","HR_Admin"]
            },
            {
              type: 'link',
              label: ' رصيد الايجازات',
              route: '/leaveBalance',
              icon: 'mat:work_outline',roles :["System_Administrator"]
            },
            {
              type: 'link',
              label: 'السجلات الحضور والانصراف',
              route: '/logs',
              icon: 'mat:calendar_today',roles :["System_Administrator","HR_Admin"]
            },

            {
              type: 'link',
              label: 'المسميات الوظفيه',
              route: '/designation',
              icon: 'mat:launch',roles :["System_Administrator"]
            },
/////////
            {
              type: 'link',
              label: 'إدارة العلاوات',
              route: '/allowance-management',
              icon: 'mat:money' ,roles :["System_Administrator","HR_Admin"]
            },
            {
              type: 'link',
              label: 'الموافقات',
              route: '/approvals',
              icon: 'mat:check_circle' ,roles :["System_Administrator","HR_Admin"]
            },
            {
              type: 'link',
              label: 'الأرشيف',
              route: '/archive',
              icon: 'mat:archive', roles :["Archive_Viewer","Archive_Editor","Archive_Admin","System_Administrator"]
          
            },
            {
              type: 'link',
              label: 'عرض البريد المؤرشف',
              route: '/archived-mail-view',
              icon: 'mat:mail', roles :["Archive_Viewer","Archive_Editor","Archive_Admin","System_Administrator"]
            },
            {
              type: 'link',
              label: 'واجهة التدقيق',
              route: '/audit-interface',
              icon: 'mat:visibility', roles :["Archive_Admin","System_Administrator"]
            },            
            {
              type: 'link',
              label: 'إدارة المالية',
              route: '/financial-management',
              icon: 'mat:account_balance', roles :["financial_Viewer","financial_Editor","financial_Admin","System_Administrator"]
            },
            {
              type: 'link',
              label: 'تقارير المالية',
              route: '/financial-reports',
              icon: 'mat:bar_chart', roles :["financial_Viewer","financial_Editor","financial_Admin","System_Administrator"]
            },
            {
              type: 'link',
              label: 'المخزون',
              route: '/inventory',
              icon: 'mat:inventory', roles :["Inventory_Viewer","Inventorye_Editor","Inventory_Admin","System_Administrator"]
            },
            {
              type: 'link',
              label: 'أرشفة البريد الصادر',
              route: '/outgoing-mail-archiving',
              icon: 'mat:outbox', roles :["Archive_Viewer","Archive_Editor","Archive_Admin","System_Administrator"]
            },
            {
              type: 'link',
              label: 'إدارة الصلاحيات',
              route: '/permissions-management',
              icon: 'mat:lock',roles :["Archive_Admin","System_Administrator"]
            },
            {
              type: 'link',
              label: 'المنتجات',
              route: '/product',
              icon: 'mat:widgets',  roles :["Inventory_Viewer","Inventorye_Editor","Inventory_Admin","System_Administrator"]
            },
            {
              type: 'link',
              label: 'إدارة الترقيات',
              route: '/promotion-management',
              icon: 'mat:trending_up', roles :["System_Administrator","HR_Admin"]
            },
            {
              type: 'link',
              label: 'التقارير',
              route: '/report',
              icon: 'mat:report',   roles :["Reporting_Admin","Reporting_Viewer","System_Administrator"]
            },
            {
              type: 'link',
              label: 'إعدادات المرتبات',
              route: '/salary-settings',
              icon: 'mat:settings', roles :["System_Administrator","HR_Admin","financial_Editor","financial_Admin"]
            },
 

            {
              type: 'link',
              label: ' مدير الحسابات',
              route: '/Admin',
              icon: 'mat:settings', roles :["System_Administrator"]
            },

          
            {
              type: 'link',
              label: 'الرئيسية',
              icon: 'mat:home',
              route: '/home'


            },
      
 

  ],
}


]}ngOnInit() {
 
    // Load the current user
 

   
  
  }
;




 
}
