


import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, filter } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';

import { ConfirmDialogComponent } from '../ShareComponent/confirm-dialog/confirm-dialog.component';
import { stagger, trigger, style, transition, animate, state } from '@angular/animations';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Department } from 'src/assets/models/employees.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { DepartmentNode, DepartmentsService, FlatNode } from '../departments/departments.service';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { ToastrService } from 'ngx-toastr';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { DepartmentCreateUpdateComponent } from '../departments/department-create-update/department-create-update.component';

@Component({
  selector: 'LIBYANA-departmentsExport',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],

  animations: [
    fadeInUp400ms,fadeInRight400ms ,
    stagger40ms,stagger,scaleIn400ms,
      trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],


  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
@UntilDestroy() // إضافة هذا الديكور هنا

export    class DepartmentsExportComponent  implements OnInit, AfterViewInit  {
  empForm: FormGroup;
  departments: Department[]; 
 selectedDepartments: FlatNode;  
  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<DepartmentNode, FlatNode>;
  dataSourceTree: MatTreeFlatDataSource<DepartmentNode, FlatNode>;
  departmentDetails:any; 
  constructor(private fb: FormBuilder, private dialog: MatDialog, private departmentsService: DepartmentsService,private toastr:ToastrService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
    this.dataSourceTree = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
     this.departmentsService.getDepartment().subscribe((rep)=>{
      this.departments= rep;
     })
    // this.empForm = this.fb.group({
    //   id: [this.departmentDetails?.id||0],
    //   name: [this.departmentDetails?.name||'0'],
    //   description: [this.departmentDetails?.description||'0'],
    //   parentDepartmentId: [this.departmentDetails?.parentDepartmentId||'0']
    // });
 
  
  
  
  }




  // updateDepartment(id: number, name?: string, description?: string, parentDepartmentId?: number) {
  //   const updateDto: any = {
  //     name,
  //     description,
  //     parentDepartmentId
  //   };

  //   this.departmentsService.updateDepartment(updateDto).subscribe(
  //     response => {
  //       console.log('Department updated successfully', response);
  //     },
  //     error => {
  //       console.error('Error updating department', error);
  //     }
  //   );
  // }


  createNew() {

 
    const dialogRef = this.dialog.open(DepartmentCreateUpdateComponent, {
      // width: '700px',
      height:'250px',
      data: this.selectedDepartments
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.toastr.success('Employee updated successfully.');

        this.departmentsService.getDepartmentHierarchy().subscribe(data => {
          this.dataSourceTree.data = data;
         
      },
      error => {
        console.error('Error updating employee', error);
        this.toastr.error('Failed to update employee.');
      }
       //// this.deleteDepartment(depId);
      )  }
  }); 
  }


  ngAfterViewInit(): void {
    
  }
  confirmDelete(depId: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { message: 'Are you sure you want to delete this department?' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        
        this.deleteDepartment(depId);
      }
    });
  }

  deleteDepartment(depId: number) {
    // Call your service to delete the department
    this.departmentsService.deleteDepartment(depId).subscribe((rep) => {
       this.toastr.success('تم حذف بنجاح ,أعادة ضبط صفحة', 'Success');
  
    }, error => {
       this.toastr.error('Error', 'error');
    } //,()=>    window.location.reload()
    ); 
  }

  ngOnInit(): void {
       this.departmentsService.selectedDepartment$.pipe(untilDestroyed(this)).subscribe((department) => {
        this.selectedDepartments=department;
      if(department!==null)
      this.loadDepartmentDetails(department?.id); // Update the filter parameter     
    });

    this.departmentsService.getDepartmentHierarchy().subscribe(data => {
      this.dataSourceTree.data = data;
     });

  }
  
  loadDepartmentDetails(depId: number): void {
    this.departmentsService.getDepartmentDetails(depId).subscribe(
      (data) => {
        
        this.departmentDetails = data;
        this.empForm = this.fb.group({
          id: [this.departmentDetails?.id||0],
          name: [this.departmentDetails?.name||'0'],
          description: [this.departmentDetails?.description||'0'],
          parentDepartmentId: [Number(this.departmentDetails?.parentDepartmentId)||0]
        });     },
      error => console.error(error)
    );
  }

  downloadFile(depId:any): void { 
    this.departmentsService.downloadEmployeesFile(depId).subscribe(
      blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `user.dat`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.toastr.success('Department download successfully!', 'Success'); 
      },
      error => console.error(error)
    );
  }
  downloadxls(dep: any): void {
     this.departmentsService.downloadxls(dep.id);
 
}

 
 transformer = (node: DepartmentNode, level: number) => {
  return {
    expandable: !!node.children && node.children.length > 0,
    name: node.name,
    level: level,
    departmentId: node.departmentId,
    isSelected: node.isSelected || false
  };
}

getLevel = (node: FlatNode) => node.level;
isExpandable = (node: FlatNode) => node.expandable;
getChildren = (node: DepartmentNode): DepartmentNode[] => node.children || [];
hasChild = (_: number, node: FlatNode) => node.expandable; 
onNodeClick(node: FlatNode): void {
  if (this.treeControl.isExpanded(node)) {
    this.treeControl.collapse(node);
  } else {
    this.treeControl.expand(node);
  }
  this.selectedDepartments = node;
  console.log(this.selectedDepartments)
  this.empForm.get('parentDepartmentId').setValue(this.selectedDepartments.departmentId);
}

toggleNodeSelection(node: FlatNode): void {
  this.treeControl.dataNodes.forEach(n => {
    n.isSelected = false;
  });  
  node.isSelected = true;    
//  this.updateSelectedDepartments();
  this.selectedDepartments = node;
  console.log(this.selectedDepartments)
  this.empForm.get('parentDepartmentId').setValue(this.selectedDepartments.departmentId);
}

 

updateSelectedDepartments() {
  if (this.empForm.valid) {
    const updatedEmployee = this.empForm.value;
    this.departmentsService.updateDepartment(updatedEmployee).subscribe(
      response => {
        this.toastr.success('Employee updated successfully.');

        this.departmentsService.getDepartmentHierarchy().subscribe(data => {
          this.dataSourceTree.data = data;
         });
      },
      error => {
        console.error('Error updating employee', error);
        this.toastr.error('Failed to update employee.');
      }
    );
  }
}
  
}