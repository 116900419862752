import { Component, Input, OnInit } from '@angular/core';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { PaymentAcademicYear } from 'src/assets/models/parent';

@Component({
  selector: 'vex-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class InvoiceComponent implements OnInit {
  @Input() public data :PaymentAcademicYear;

  constructor() { }

  ngOnInit() {
  }

}
