import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { friendSuggestions } from '../../../static-data/friend-suggestions';
import { fadeInUp400ms } from '../../../@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from '../../../@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from '../../../@vex/animations/scale-in.animation';
import { stagger40ms } from '../../../@vex/animations/stagger.animation';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { Router } from '@angular/router';
import Pusher from 'pusher-js' ; 
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'vex-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class QuickpanelComponent implements OnInit {

  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');


      myimagePath = [
    {
      path: 'http://80.209.237.254/projects/manhaji_student/',
      avatars : "public/img/avatars/" ,
    },
  ];


  
  suggestions =  [
      {
    name: 'w.abuaziz',
    userid:60,
    imageSrc: this.myimagePath[0]['path'] + this.myimagePath[0]['avatars'] +  '4.jpg',
    level: 6,
    added: true
  },
  {
    name: 'aimen.gumati',
    userid:61,
    imageSrc: this.myimagePath[0]['path'] + this.myimagePath[0]['avatars'] +  '1.jpg',
    level: 5,
    added: true
  },
  {
    name: 'zakialfasi',
    userid:63,
    imageSrc: this.myimagePath[0]['path'] + this.myimagePath[0]['avatars'] +  '2.jpg',
    level: 3,
    added: true
  },
  {
    name: 'Libyan6',
    userid:62,
    imageSrc: this.myimagePath[0]['path'] + this.myimagePath[0]['avatars'] +  '3.jpg',
    level: 3,
    added: true
  },
  {
    name: 'w@w',
    userid:64,
    imageSrc: this.myimagePath[0]['path'] + this.myimagePath[0]['avatars'] +  '4.jpg',
    level: 3,
    added: true
  }]



  constructor(
    private API:BackendApiService,
    private router:Router,
    private http:HttpClient
  ) { }

  ngOnInit() {
  }


  public pusherForm ={
    username:'waim',
    message:'hi pusher',
    userid: 0 
};


  sendChallange(userid){

    this.pusherForm.userid = userid ; 
     //  alert(userid) ; 
    this.API.sendPusher(this.pusherForm).subscribe(
      data =>  console.log(data),
      error=>  console.log(error)  
    );


  }

 

}
