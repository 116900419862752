  <div class="body-2 p-6 bg-primary text-primary-contrast">
  <p> المستخدمون النشطون  </p>
  
</div>




<div @stagger class="px-6 py-4 flex flex-col gap-4">
  <div *ngFor="let friend of suggestions; " class="flex items-center">
    <img @scaleIn [src]="friend.imageSrc" alt="Profile Picture" class="avatar ltr:mr-3 rtl:ml-3 flex-none"/>
    <div @fadeInRight class="flex-auto">
      <h4 class="body-2 m-0 leading-snug">{{ friend.name }}</h4>
      <h5 class="text-secondary m-0 caption leading-none"> المستوى   {{ friend.level }}  </h5>
    </div>
    <button   *ngIf="!friend.added" color="primary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:person_add"></mat-icon>
    </button>

    <button  (click) ="sendChallange(  friend.userid  )"  *ngIf="friend.added" color="primary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:touch_app"></mat-icon>
    </button>
  </div>
</div>

















<!-- 
<mat-divider></mat-divider>

<mat-nav-list>
  <h3 matSubheader>UPCOMING EVENTS</h3>
  
  
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Business Meeting</h4>
    <p matLine>In 16 Minutes, Meeting Room</p>
  </a>
 
   
  <mat-divider></mat-divider>
  <h3 matSubheader>SERVER STATISTICS</h3>
</mat-nav-list>

<div class="vex-list-item" matRipple>
  <p>CPU Load (71% / 100%)</p>
  <p class="progress-bar">
    <mat-progress-bar color="primary" mode="determinate" value="71"></mat-progress-bar>
  </p>
</div>

  -->
 


