import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, catchError, map, of, tap, throwError } from 'rxjs';
import { Emp, EmpParams, Employees } from 'src/assets/models/employees.model';
import { IPaginationSite } from 'src/assets/models/site';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {  
  private apiUrl = environment.apiUrl;
  private empssSource = new BehaviorSubject<Employees[]>([]);
  public currentSitesSources = new ReplaySubject<Employees>(1);
  currentSites$ = this.currentSitesSources.asObservable();
   siteCache= new Map();
  constructor(private http: HttpClient ,private taster:ToastrService) {} 
  addLevesPatch(data: any): Observable<any> {
    const formData = new FormData();
    formData.append('AddToData', data.AddToData);
    formData.append('file', data.file);
    return this.http.post(this.apiUrl+'/emps' + '/import', formData)
        .pipe(
            map(response => {
                this.taster.success('تم الايجازات بنجاح', 'Success');
                return response;
            }),
            catchError(error => {
                this.taster.error('فشل اضافة الايجازات راجع فورمات', 'Error');
                return of(null);
            }));
}
searchEmployee(txt: string): Observable<Emp> {
  return this.http.get<Emp>(`${this.apiUrl}/emps/Search?txt=${txt}`);
}
updateEmployee(employee: Emp): Observable<Emp> {
  return this.http.put<Emp>(`${this.apiUrl}/emps/${employee.id}`, employee);
}

addEmployee(employee: Emp): Observable<Emp> {
  return this.http.post<Emp>(`${this.apiUrl}/emps`, employee);
}
getEmployees(SiteParams: EmpParams) {
      const respnse =this.siteCache.get(Object.values(SiteParams).join('-'));
     if(respnse) {
      return of(respnse);
     }
      let params = new HttpParams();
      if (SiteParams?.groupId ) {
        params = params.append('groupId', SiteParams.groupId);
      }
      if (SiteParams?.depId ) {
        params = params.append('depId', SiteParams.depId);
      }
      if (SiteParams?.search ) {
        params = params.append('search', SiteParams.search);
      }
      params = params.append('sort', SiteParams.sort);
      params = params.append('pageIndex', (SiteParams.pageNumber).toString());
      params = params.append('pageSize', SiteParams.pageSize.toString());
      return this.http.get<IPaginationSite>(this.apiUrl+'/emps' , { observe: 'response', params }).pipe(
        map(response => {
          this.siteCache.set( Object.values(SiteParams).join('-') , response.body);
          return   ( response.body);
        }), catchError((error) => {
          this.taster.error(error.message);  
          return throwError(error);
        })
      );
      }

  // New method to delete an employee
  deleteEmployee(employeeId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/emps/${employeeId}`).pipe(
      map(response => {
        this.taster.success('Employee deleted successfully', 'Success');
        return response;
      }),
      catchError(error => {
        this.taster.error('Failed to delete employee', 'Error');
        return throwError(error);
      })
    );
  }

    }
