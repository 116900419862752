import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFormattedInput]'
})
export class FormattedInputDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    // Remove all non-digit characters
    const digitsOnly = value.replace(/\D/g, '');

    // Split the digits and join with slashes
    const formattedValue = 'S' + digitsOnly.split('').join('/');

    // Update the input field value only if it's not undefined
    if (formattedValue !== 'undefined') {
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    }
  }
}
