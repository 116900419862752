import { Component, Inject, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  // styleUrls: ['./camera-dialog.component.css'],
})
export class CameraDialogComponent {
  isCamerOpen = false; // Initially, the camera is closed
  id: number; // Replace with your logic to determine the camera id

  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  @Output() imageCaptured = new EventEmitter<{ dataUrl: string, type: number }>();

  constructor(
    public dialogRef: MatDialogRef<CameraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  // Open the camera when the dialog is opened
  ngAfterViewInit() {
    this.openCamera();
  }

  closeCamera() {
    this.isCamerOpen = false;
    if (this.cameraStream) {
      const tracks = this.cameraStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }

  openCamera() {
    if (this.videoElement && this.videoElement.nativeElement) {
      const video: HTMLVideoElement = this.videoElement.nativeElement;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            this.cameraStream = stream;
            video.srcObject = stream;
            video.play();
            this.isCamerOpen = true;
          })
          .catch((error) => {
            console.error('Error accessing the camera:', error);
          });
      } else {
        console.error('getUserMedia API is not supported in this browser.');
      }
    }
  }

  captureImage(type: number) {
    if (this.isCamerOpen) {
      const video = this.videoElement.nativeElement;
      const canvas = this.canvas.nativeElement; // Use the canvas element
      const context = canvas.getContext('2d');

      // Set canvas dimensions to match the video frame
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current video frame on the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      if (!context) {
        console.error('Canvas context is invalid.');
        return;
      }
      // Convert the canvas content to an image data URL
      const imageDataUrl = canvas.toDataURL('image/jpeg'); // You can adjust the image format if needed

      // Emit the captured image data URL and type to the parent component
      this.imageCaptured.emit({ dataUrl: imageDataUrl, type });

      // Close the camera
    //  this.closeCamera();
    }
  }

  cameraStream: any;
}
