import { Injectable , Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { AuthService } from './auth.service';
import { UserService } from '../user/user.service';

@Injectable()

export class TokenceptorService implements HttpInterceptor{
  [x: string]: any;


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //  let authService = this.Injector.get(AuthService)
   var getToken= localStorage.getItem('token');
    let toeknizedReq = req.clone({
      setHeaders: {
        Authorization :  `Bearer ${getToken}`
      }
    })
    return next.handle(toeknizedReq)
  }



}


