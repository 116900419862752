import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'vex-hight-charts',
  templateUrl: './hight-charts.component.html',
  styleUrls: ['./hight-charts.component.scss']
})
export class HightChartsComponent implements OnInit {

map!: google.maps.Map;
markers: google.maps.Marker[] = [];
data: any[] = []; // U This should be replaced with the actual data from your API

    constructor(private http: HttpClient) {}




    ngOnInit() {
      this.initializeMap();
      this.fetchData(); // Fetch data from the API and set this.data
    }

    initializeMap() {
      this.map = new google.maps.Map(document.getElementById('map')!, {
        center: { lat: 0, lng: 0 },
        zoom: 10,
      });
    }

    createMarkers() {
      for (const location of this.data) {
        const latitude = parseFloat(location.latitude);
        const longitude = parseFloat(location.longitude);

        if (isNaN(latitude) || isNaN(longitude)) {
          console.error('Invalid latitude or longitude:', location.latitude, location.longitude);
          continue; // Skip this location and proceed with the next one
        }

        const marker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: this.map,
          title: location.site_name,
        });

        // Optionally, add click event listener to show additional information for marker
        marker.addListener('click', () => {
          const infoWindow = new google.maps.InfoWindow({
            content: `<h3>${location.site_name}</h3><p>Vendor: ${location.vendor}</p>`,
          });
          infoWindow.open(this.map, marker);
        });

        this.markers.push(marker);
      }

    }

    fetchData() {
      this.http.get<any[]>('http://localhost:5128/cdr').subscribe((response) => {
        console.log(response); // Check the response in the browser console
        this.data = response;
        console.log(response)
        this.createMarkers();
      });
    }
  }
