<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <img *ngIf="contact?.imageSrc" [src]="contact?.imageSrc" class="avatar ltr:mr-5 rtl:ml-5"/>
    <h2 class="headline m-0 flex-auto">{{ form.get('name').value || 'New Contact' }}</h2>

    <button (click)="toggleStar()" class="text-secondary" mat-icon-button type="button">
      <mat-icon *ngIf="contact?.starred" class="text-amber" svgIcon="mat:star"></mat-icon>
      <mat-icon *ngIf="!contact?.starred" svgIcon="mat:star_border"></mat-icon>
    </button>

    <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <mat-form-field class="mt-6">
      <mat-label>Name</mat-label>
      <input cdkFocusInitial formControlName="name" matInput>

      <mat-icon class="ltr:mr-3 rtl:ml-3" matPrefix svgIcon="mat:person"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input formControlName="phone" matInput>

      <mat-icon class="ltr:mr-3 rtl:ml-3" matPrefix svgIcon="mat:phone"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>E-Mail</mat-label>
      <input formControlName="email" matInput>

      <mat-icon class="ltr:mr-3 rtl:ml-3" matPrefix svgIcon="mat:email"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Company</mat-label>
      <input formControlName="company" matInput>

      <mat-icon class="ltr:mr-3 rtl:ml-3" matPrefix svgIcon="mat:business"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Birthday</mat-label>
      <input [matDatepicker]="datepickerRef" formControlName="birthday" matInput>
      <mat-datepicker-toggle [for]="datepickerRef" class="block" matPrefix></mat-datepicker-toggle>
      <mat-datepicker #datepickerRef></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea formControlName="notes" matInput></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-flat-button type="submit">
      <ng-container *ngIf="isEdit">Update Contact</ng-container>
      <ng-container *ngIf="!isEdit">Create Contact</ng-container>
    </button>
  </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Print</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Export</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
