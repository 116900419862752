
      <canvas #canvas style="display: none;"></canvas>
      <div class="mb-0 body-1" mat-dialog-title>
       <h2>      <mat-icon class="ltr:mr-1 rtl:ml-2 text-secondary flex-none" svgIcon="mat:camera_alt"></mat-icon>
       </h2>
       <div>
        <div class="mt-3 ltr:ml-10 rtl:mr-10 flex flex-wrap items-start justify-between">
          <div class="members flex-none">
           <button      class="flex-auto ltr:mr-4 rtl:ml-1 text-secondary"
             color="accept"
             (click)="captureImage(2)"
             mat-icon-button
             type="button"
             matTooltip="صورة العميل" >
             <mat-icon svgIcon="mat:portrait"></mat-icon>
           </button></div>
           <div class="labels max-w-full flex-none">
           <button   class="flex-auto ltr:mr-4 rtl:ml-1 text-secondary"
             color="primary"
             (click)="captureImage(1)"
             mat-icon-button
             type="button"
             matTooltip="صورة صك صرف" >
           <i class="fa fa-fa-money-check-alt"></i>
             <mat-icon svgIcon="mat:local_atm"></mat-icon>
           </button>
         </div>
        </div>
       </div>
       <mat-divider class="-mx-6 mt-6"></mat-divider>
     </div>

     <mat-dialog-content class="content">
       <div class="ltr:ml-10 rtl:mr-10 mt-2">
         <video #videoElement class="captured-image" controls width="250" height="250"></video>
       </div>
     </mat-dialog-content>

     <mat-dialog-actions align="end">
       <button mat-button mat-dialog-close type="button" (click)="closeCamera()"    matTooltip="اغلاق الكاميرة"> <mat-icon svgIcon="mat:videocam_off"></mat-icon>

       </button>
      </mat-dialog-actions>

